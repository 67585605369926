import {Col, React, Row, Table} from "../../../../common/web_common/components/eevi_react_exports";
import {EventDetailsData} from "./event_components";
import {strEnum} from "../../../../common/web_common/components/eevi_transform";
import {EeviCoreContext} from "../../../../common/web_common/core/components/eevi_core_context";
import {Carer} from "../../../../common/web_common/core/eevi_core_data";

const TAKEN_THRESHOLD = 20*60*1000 //ms
const OPEN_THRESHOLD = 5*60*1000 //ms
export const QUERY_WINDOW = 12*60*60*1000; //ms <- 12 hours

const _durationFormat = strEnum(
    "MINUTE_ONLY",
    "HH:MM",
    "MM:SS",
    "HH:MM:SS"
)
type _durationFormat = Omit<keyof typeof _durationFormat, "[Symbol.iterator]">;

enum EventStatus{
    DANGER,
    WARNING,
    SAFE,
    UNKNOWN
}

interface SmartAnnunciatorSetting {
    carer_availability_message: boolean;
    carer_availability_alarm: boolean;
}

export interface CarerAvailabilityData {
    careGroupCode: string;
    careGroupName: string;
    config: SmartAnnunciatorSetting;
    totalAvailableCarers: number;
    availableCarers: Carer[];
}

export class RealtimeEventCompute {
    protected get base(): EventDetailsData {
        return this as any;
    }

    get responderFullName(): string | undefined{
        if (this.base.eventType === "ASK_ASSISTANCE") {
            return this.base.assistantFullName;
        } else if (this.base.eventType === "RAISE_EMERGENCY") {
            return this.base.emergencyFullName;
        } else {
            return this.base.carerFullName;
        }
    }

    get elapsedTime(): number {
        const now = new Date()
        return (now.valueOf() - Date.parse(this.base.triggeredTime))
    }

    get status(): EventStatus {
        return (this.isCompleted || this.isPresented) ? EventStatus.SAFE
                    : this.isTaken ? EventStatus.WARNING : EventStatus.DANGER
    }

    get active(): boolean {
        return !this.isCompleted // open, taken, presence
    }

    get isTechSupport(): boolean {
        return this.base.eventType === "DEVICE_FAULT" || this.base.eventType === "SYSTEM_FAULT"
    }

    // Event States
    get isOpen(): boolean {
        return this.base.eventState === "OPEN"
    }

    get isTaken(): boolean {
        return this.base.eventState.includes("TAKEN") || Boolean(this.responderFullName)
    }

    get isPresented(): boolean { //Only mark as presented if previously taken to avoid false presence
        return (this.isTaken && Boolean(this.base.arriveTime))
    }

    get isCompleted(): boolean {
        return this.base.eventState === "COMPLETED"
    }

    // Conditional format
    get isOverThreshold(): boolean {
        if (this.isCompleted || this.isPresented){
            return false
        } else if (this.isTaken) {
            return this.elapsedTime >= TAKEN_THRESHOLD
        } else {
            return this.elapsedTime >= OPEN_THRESHOLD
        }
    }

    get withinQueryWindow(): boolean {
        // Ignore any event outside of query window
        return this.elapsedTime <= QUERY_WINDOW
    }
}

export type RealtimeEvent = RealtimeEventCompute & EventDetailsData

function toTimeString(duration: number, format: _durationFormat = "MINUTE_ONLY"): string{
    function _prefix_w_zero(arr: number[]){
        return arr.map(i => {
            return i < 10 ? `0${i}` : `${i}`
        })
    }

    if (duration) {
        const hour = Math.floor(duration/(60*60*1000))
        let min = Math.floor((duration % (60*60*1000)) / (60*1000))
        const sec = Math.floor(duration % (60*1000) / 1000)
        switch (format){
            case "HH:MM:SS":
                return _prefix_w_zero([hour, min, sec]).join(':')

            case "MINUTE_ONLY":
                min = Math.floor(duration/(60*1000))
                return `${min} ` + (min <= 1 ? "min": "mins")

            case "HH:MM":
                return _prefix_w_zero([hour, min]).join(':')

            case "MM:SS":
                min = Math.floor(duration/(60*1000))
                return _prefix_w_zero([min, sec]).join(':')

            default: // Same as "HH:MM:SS"
                return _prefix_w_zero([hour, min, sec]).join(':')
        }
    }else{
        return ''
    }

}

export function playTone(audio: HTMLMediaElement, error_handler: any = ()=>{}, callback: any = ()=>{}){
    if (audio) {
        let playPromise = audio.play()
        if (playPromise !== undefined) {
            playPromise.then(callback).catch(error_handler)
        } else {
            console.warn("Your browser does not support autoplay. Please choose a different browser.")
        }
    } else {
        console.log("No audio file provided")
    }
}

function AlarmRow (
        props:{
            event: RealtimeEvent,
            width:number,
            height:number,
            setAlarm: CallableFunction,
            hideResidentDetails:boolean
        }
): JSX.Element {
    const e = props.event
    const location = e.location
    const resident = e.residentFullName
    const eventType = e.eventTitle
    const takenCarer = e.responderFullName || (e.isOverThreshold ? "Not taken" : "")
    const presenceCarer = e.arrivedCarer || ""
    const activeTime = toTimeString(e.elapsedTime)
    const hideResidentDetails = props.hideResidentDetails

    const taken_icon = e.isTaken ? 'taken-icon' : e.isOverThreshold ? 'danger-icon' : ''
    const presence_icon = e.isPresented ? 'presence-icon' : (e.isTaken && e.isOverThreshold) ? 'warning-icon' : ''

    const background = EventStatus[e.status].toLowerCase()
    const text_format = e.isOverThreshold ? 'alert' : ''  // highlight text with red color
    const long_text_format = (t:string, maxLength:number) => {
        return (t && t.length > maxLength) ? "flex-start" : "center"
    } // re-align string t if it is too long

    let resident_td = <td style={{alignItems:`${long_text_format(resident, 60)}`}} className="flex-3">{resident}</td>

    return <tr className={`d-flex`} style={{height: `${props.height-1}vh`, marginBottom: '1vh'}}>
        {/* Flag indicator */}
        <td className={`${background}-background flex-1`} />

        <td
            style={{alignItems:`${long_text_format(location, 60)}`}}
            className="flex-3"
        >{location}</td>

        {!hideResidentDetails ? resident_td : null }

        <td className="flex-2">{eventType}</td>

        {/* Taken icon */}
        <td className={`${taken_icon} flex-1`}/>

        {/* takenCarer is a bit different from the rest of the text,
         as it includes the format alert-text along with phrase "Not taken" when it's over the threshold*/}
        <td
            style={{alignItems:`${long_text_format(takenCarer, 45)}`}}
            className={`${e.isTaken ? '' : text_format}-text flex-2`}
        >{takenCarer}</td>

        {/* Presence icon */}
        <td className={`${presence_icon} flex-1`}/>
        <td
            style={{alignItems:`${long_text_format(presenceCarer, 45)}`}}
            className="flex-2"
        >{presenceCarer}</td>

        <td className={`${text_format}-text flex-2`}>{activeTime}</td>
    </tr>
}

function Pagination(props: {currentPage: number, totalPages: number, onPageChange: CallableFunction}): JSX.Element{
    React.useEffect(() => {
        const timer = setInterval(
            (max: number)=>{
                console.log("Page Changed")
                props.onPageChange((p:number) => {
                    return (p + 1) % max
                })
            },
            10000,
            props.totalPages + 1
        )

        // Make sure that currentPage is less than totalPage
        // Sometimes, what happens is a list of event could change from
        // 6->7 (totalPages: 2->1), for a brief moment before the timer timed out,
        // the current page is has not been refreshed yet -> no items in it
        // empty HTML tag got displayed
        if (props.currentPage > props.totalPages) {
            console.log("Page Reset")
            props.onPageChange(0)
        }

        return () => clearInterval(timer)
    }, [props.totalPages])

    return <tr>
        <td className="table-footer">
            {`Page ${props.currentPage + 1} / ${props.totalPages + 1}`}
        </td>
    </tr>
}

function BlankRow(props:{width: number, height: number}): JSX.Element{
    return <tr style={{background: "transparent", width: `${props.width}vw`, height: `${props.height-1}vh`}}/>
}

function NoActiveEventsMessage(props:{width: number, height: number}): JSX.Element{
    return <tr style={{background: "transparent",width: `${props.width}vw`, height: `${props.height-1}vh`}}>
        <td className="no-active-events">There are no active Events</td>
    </tr>
}

function CarerMessage(
        props: {
            key: string,
            payload: CarerAvailabilityData,
            width: number,
            setAlarm:CallableFunction
        }
): [JSX.Element, number] {
    const style = {marginBottom: '1vh'}
    let message = `${props.payload.careGroupName}: `
    let carerStatus = "safe"
    let message_height = 0

    if (props.payload.totalAvailableCarers == 0){
        message += "No carer logged in."
        carerStatus = "danger"
        if (props.payload.config.carer_availability_alarm){
            if (props.setAlarm) props.setAlarm()
        }
    } else if (props.payload.totalAvailableCarers == 1) {
        message += `1 carer logged in: ${props.payload.availableCarers[0].carerName}.`
        carerStatus = "warning"
    } else {
        const fullNames = props.payload.availableCarers.map((c) => c.carerName).sort()
        message += `${props.payload.totalAvailableCarers} carers logged in: `
        message += `${fullNames.slice(0,-1).join(", ")}, and ${fullNames.slice(-1)}.`
    }

    // calculate the height of this message, and then remove it from the DOM immediately
    const text_box_sample = document.createElement('div')
    text_box_sample.className = 'hidden'
    text_box_sample.style.fontSize = '2vh'
    text_box_sample.style.lineHeight = '2.4vh'
    text_box_sample.style.display = 'flex'
    text_box_sample.style.width = `${90/100 * 90}vw` // we are actually rendering td, which is 90% of tr
    text_box_sample.style.padding = '10.5px' //10.5px default padding for bootstrap table
    text_box_sample.innerText = message

    document.body.appendChild(text_box_sample)
    message_height = text_box_sample.scrollHeight + 4; // top and bottom border 2px
    // Remove it from table immediately, do not render
    document.body.removeChild(text_box_sample)

    return [
        <tr key={props.key} style={style}>
            <td className={`${carerStatus}-background flex-1`} />
            <td style={{justifyContent: "start", flex: 11}}>{message}</td>
        </tr>,
        message_height
    ]
}

export function AlarmTable (props: {activeEvents: RealtimeEvent[], availableCarers: Map<string,CarerAvailabilityData>, setAlarm: CallableFunction, hideResidentDetails:boolean }): JSX.Element{
    // Percentage with respect to viewport - to ensure everything fit in one window,
    // to be refactored once we decided to separate availableCarers and activeEvents
    const [maxWidth, maxHeight] = [90, 80]  //vw, vh
    const eventRowHeight = 11 //vh

    const carerRows: JSX.Element[] = []
    let carerTableHeight = 0 //vh

    //let hideResidentDetails:boolean = true;

    props.availableCarers.forEach(
        (v) => {
            if (v.config.carer_availability_message) {
                const [message_row, height] = CarerMessage({
                    key:v.careGroupCode,
                    payload:v,
                    width:maxWidth,
                    setAlarm:props.setAlarm
                })

                carerRows.push(message_row)
                carerTableHeight += (height*100/window.innerHeight + 1) //1vh margin bottom
            }
        }
    )

    const carerTable = <tbody style={{paddingTop: "1vh", borderTop: "2px solid #DCDCD7"}}>
                {carerRows}
        </tbody>
    carerTableHeight += 1 //padding top 1vh

    const eventTableHeight = maxHeight - Math.ceil(carerTableHeight)
    let eventsPerPage = Math.floor(eventTableHeight/eventRowHeight)

    const [currentPage, setCurrentPage] = React.useState(0)
    const totalPages = Math.max(Math.floor((props.activeEvents.length - 1) / eventsPerPage), 0)

    const startIndex = currentPage * eventsPerPage
    const endIndex = startIndex + eventsPerPage
    const displayedEvents = props.activeEvents.slice(startIndex, endIndex)

    const eventTable: JSX.Element[] = displayedEvents.map(
        (e) => {
            return <AlarmRow
                key={e.eventId}
                event={e}
                width={maxWidth}
                height={eventRowHeight}
                setAlarm={props.setAlarm}
                hideResidentDetails={props.hideResidentDetails}
            />
        }
    );

    const emptyAreaHeight = eventTableHeight - eventTable.length * eventRowHeight
    if (emptyAreaHeight){
        if (eventTable.length){
            eventTable.push(<BlankRow key={"blank"} width={maxWidth} height={emptyAreaHeight}/>)
        } else {
            eventTable.push(
                <NoActiveEventsMessage key={"no-event-message"} width={maxWidth} height={emptyAreaHeight}/>
            )
        }
    }

    const table = <Table id={"alarm-table"} className="d-flex flex-column align-items-center">
        <thead>
            <tr>
                <th className="flex-1"/>
                <th className="flex-3">Location</th>
                {!props.hideResidentDetails ? <th className="flex-3">Resident</th> : null }
                <th className="flex-2">Event</th>
                <th className="flex-1"/>
                <th className="flex-2">Taken</th>
                <th className="flex-1"/>
                <th className="flex-2">Presence</th>
                <th className="flex-2">Active Time</th>
            </tr>
        </thead>
        <tbody>
            {eventTable}
        </tbody>
        <tfoot>
            <Pagination
                currentPage={currentPage}
                totalPages={totalPages}
                onPageChange={setCurrentPage}
            />
        </tfoot>
        {carerRows.length ? carerTable: null}
    </Table>

    return <div>{table}</div>;
}

export function AlarmHeader(props: {now: Date, lastUpdate: Date}): JSX.Element{
    const {model} = React.useContext(EeviCoreContext)
    let title = ''
    if (model !== undefined) {
        if (typeof model.careGroups !== "undefined" && typeof model.careGroups !== "string") {
            title = model.careGroups.length == 1 ? model.careGroups[0].careGroupName : "Event Status"
        }
    }
    return <div className="sticky-top">
         <div className="header-alarms">
            <Row className="header-row">
                <Col md="2" className="header-logo"/>
                <Col md="5">
                    <div className="header-title">
                    <h2>{`${title}`}</h2>
                    <h4>{`${props.now.toLocaleDateString(undefined, {year: 'numeric', month: 'short', day: 'numeric' })}`}</h4>
                    </div>
                </Col>
                <Col md="5">
                    <div className="header-time">
                    <h2>
                        {`${props.now.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit', second:'2-digit' })}`}
                    </h2>
                    <h4>{`Last data refresh: ${props.lastUpdate.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' })}`}</h4>
                    </div>
                </Col>
            </Row>
        </div>
    </div>
}