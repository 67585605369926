import '../../../common/web_common/styles/eevi.css';
import '../src/styles/realtime.css';
import {EeviLogoutForm} from "../../../common/web_common/forms/eevi_logout_form";
import {Route, Router, React, ReactDOM} from "../../../common/web_common/components/eevi_react_exports";
import {getProperty} from "../../../common/web_common/components/eevi_util";
import {EeviCoreContext} from "../../../common/web_common/core/components/eevi_core_context";
import {EeviCoreModel} from "../../../common/web_common/core/eevi_core_data";
import ServiceLocationsForm from "./forms/service_locations_form";
import CarersForm from "./forms/carers_form";
import RunTestsForm from "./forms/run_tests_form";
import AccountsForm from "./forms/accounts_form";
import AllTestsForm from "./forms/all_tests_form";
import OrganisationsForm from "./forms/organisations_form";
import EventsForm from "./forms/events_form";
import EventSignalsForm from "./forms/event_signals_form";
import EeviAdminForm from "./forms/eevi_admin_form";
import AlarmsForm from "./forms/alarms_form";
import DeviceConnectivityForm from "./forms/device_connectivity_form";
import Dashboard from "./components/dashboard";

console.info("Eevi: " + getProperty(window, "stageType", ""));
const model = new EeviCoreModel();
ReactDOM.render(
    <EeviCoreContext.Provider value={{model: model}}>
        <Router>
            <Route exact path='/(|index.html|index|residents)'><ServiceLocationsForm/></Route>
            <Route exact path='/carers'><CarersForm/></Route>
            <Route exact path='/events'><EventsForm/></Route>
            <Route path='/event_signals/:eventId' component={EventSignalsForm} />
            <Route exact path='/tests'><AllTestsForm/></Route>
            <Route path='/tests/:id' component={RunTestsForm}/>
            <Route exact path='/organisations'><OrganisationsForm/></Route>
            <Route exact path='/accounts'><AccountsForm/></Route>
            <Route exact path='/event_status'><AlarmsForm/></Route>
            <Route exact path='/logout' component={EeviLogoutForm}/>
            <Route exact path='/eevi_admin' component={EeviAdminForm}/>
            <Route exact path='/device/connectivity' component={DeviceConnectivityForm}/>
            <Route exact path='/dashboard' component={Dashboard}></Route>
        </Router>
    </EeviCoreContext.Provider>,
    document.getElementById('app')
);
