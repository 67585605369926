import {EeviForm, EeviFormState} from "../../../../common/web_common/forms/eevi_form";
import {React, withRouter} from "../../../../common/web_common/components/eevi_react_exports";
import {EventDetails, EventDetailsData, EventsTable} from "../components/event_components";
import {EeviApi} from "../../../../common/web_common/components/eevi_api";
import {apiV1, MenuLink} from "../../../../common/web_common/containers/eevi_std_container";
import {EeviDateRangePicker} from "../../../../common/web_common/components/eevi_date_picker";
import {eeviId, endOfDay, startOfDay} from "../../../../common/web_common/components/eevi_util";

function FourteenDaysBefore() {
    let FourteenDaysBefore = new Date();
    FourteenDaysBefore.setDate(FourteenDaysBefore.getDate() - 14);
    return FourteenDaysBefore;
}

class EventsFormState implements EeviFormState {
    loading?: boolean = true;
    nextToken?: string;
    redirect?: string;
    title?: string;
    windowHeight: number = 0;
    windowWidth: number = 0;
    error: any = undefined;
    events: EventDetailsData[] = [];
    startDate: Date = FourteenDaysBefore();
    endDate: Date = new Date();
    requestId?: string;
}


class EventsForm extends EeviForm<any, EventsFormState> {

    private readonly api: EeviApi<EventsFormState>;
    private polling = true;
    private requestId: string = "";

    constructor(props: any) {
        super(props, "Events", false, true);
        this.api = EeviApi.fromComponent<EventsFormState>(this);
        this.state = new EventsFormState();
    }

    /**
     * Top level navigation
     */
    protected menuLinks(): MenuLink[] {
        return [
            {title: 'Residents', link: '/residents'},
            {title: 'Carers', link: '/carers'},
            {title: 'Event Status', link: '/event_status'},
            {title: 'Dashboard', link: '/dashboard'}
        ];
    }

    /**
     * Initial call to the back end to load the first page of events. See also onNextPage()
     */
    protected onFormDidMount() {
        this.polling = true;
        this.api.get(
            `${apiV1}/events?` + this.getDatesPartOfUrl(),
            undefined,
            (data) => {
                if (this.polling) {
                    this.setState({...data, loading:false});
                    setTimeout(()=> this.retrieveRecentEvents(), 120000);
                }
            }
        );
    }

    protected onFormWillUnmount() {
        this.polling = false;
    }

    /**
     * Attempt to add any new events to the start of the list every 60 seconds or so.
     */
    private retrieveRecentEvents() {
        if (!this.polling) {
            return;
        }
        this.api.get(
            `${apiV1}/events?` + this.getDatesPartOfUrl(),
            undefined,
            (data: Partial<EventsFormState>) => {
                if (this.polling && data.requestId === this.requestId) {
                    this.api.setState(this.topUpWithRecentEvents(data));
                    setTimeout(()=> this.retrieveRecentEvents(), 120000);
                }
            }
        );
    }


    private getDatesPartOfUrl(): string {
        this.requestId = eeviId()
        const startDate = startOfDay(this.state.startDate, true);
        const endDate = endOfDay(this.state.endDate, true);
        return `endDate=${encodeURIComponent(endDate.toISOString())}` +
            `&startDate=${encodeURIComponent(startDate.toISOString())}` +
            `&requestId=${this.requestId}`;
    }

    /**
     * Called when the user scrolls past a certain point if this.state.nextToken exists.
     * Note: Back end code that makes use of the paging capability of the Eevi Rest classes in common
     * returns nextToken with each block of data. We make another api call but rather than directly
     * updating the form state we call this.updateNextPage() to append (rather than replace) the data.
     */
    protected onNextPage(nextToken: string) {
        const url =
            `${apiV1}/events?nextToken=${encodeURIComponent(nextToken)}&` + this.getDatesPartOfUrl();
        this.api.get(
            url, undefined, undefined, (nextPage) => this.updateNextPage(nextPage));
    }

    /**
     * Callback function from the api object because we want to append the set of events to the existing.
     */
    private updateNextPage(nextPage: Partial<EventsFormState>): Partial<EventsFormState> {
        // append new events to old
        nextPage.events = [...this.state.events, ...nextPage.events || []];
        return nextPage;
    }

    /**
     * We not only have infinite scroll appending to the bottom - we try to keep inserting new events to the top of
     * the list
     */
    private topUpWithRecentEvents(recentPage: Partial<EventsFormState>): Partial<EventsFormState> {
        if (this.state.events === undefined || this.state.events.length == 0){
            return recentPage;
        }
        const currentEventId = this.state.events[0].eventId;
        const newEvents = new Array<EventDetailsData>();
        for (const event of recentPage.events!) {
            if (event.eventId == currentEventId) {
                break;
            }
            else {
                newEvents.push(event);
            }
        }
        // try to keep existing events up to date but not at the expense of performance.
        for (const event of recentPage.events!) {
            let count = 0;
            for (let oldEvent of this.state.events) {
                if (event.eventId === oldEvent.eventId) {
                    Object.assign(oldEvent, event);
                    break;
                }
                count += 1;
                if (count > 100) {
                    break;
                }
            }
        }

        // append new events before old
        recentPage.events = [...newEvents, ...this.state.events];
        // topping up with recent events does not update nextToken because it can be updated by updateNextPage
        delete recentPage.nextToken;
        return recentPage;
    }


    onSelectRow(event: EventDetails) {
        this.setState({redirect: `/event_signals/${encodeURIComponent(event.eventId)}`})
    }

    protected renderForm(): React.ReactNode {
        const minDate = new Date(2017, 1, 1);
        const maxDate = new Date();
        const menuButtonClass = this.getMenuButtonClass(false);
        return <div className="mx-auto">
            <div className="border-bottom p-4">
                <EeviDateRangePicker
                    startDate={new Date(this.state.startDate)}
                    endDate={new Date(this.state.endDate)}
                    onChange={(startDate, endDate) => this.onChangeDateRange(startDate, endDate)}
                    menuButtonClass={menuButtonClass}
                    minDate={minDate}
                    maxDate={maxDate}
                    scaleDown={false}
                />
            </div>
            <EventsTable events={this.state.events} onSelect={(e) => this.onSelectRow(e)}/>
        </div>;

    }


    private onChangeDateRange(startDate: Date, endDate: Date) {
        this.setState({startDate: startDate, endDate: endDate, loading: true, events: []});
        setTimeout(() => this.api.get(`${apiV1}/events?` + this.getDatesPartOfUrl()), 0);
    }
}

export default withRouter(EventsForm);