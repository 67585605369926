import {apiV1, MenuLink} from "../../../../common/web_common/containers/eevi_std_container";
import {EeviApi} from "../../../../common/web_common/components/eevi_api";
import {React, withRouter} from "../../../../common/web_common/components/eevi_react_exports";
import {EeviFormState, EeviForm} from "../../../../common/web_common/forms/eevi_form";
import {
    EventAndSignalsTable, EventDetailsData,
    EventResponseData,
    SignalDetailsData
} from "../components/event_components";
import {eeviId} from "../../../../common/web_common/components/eevi_util";
import {EeviEventAccess} from "../../../../common/web_common/core/eevi_core_data";


class EventSignalsState implements EeviFormState {
    event?: EventResponseData;
    signals: Array<SignalDetailsData> = [];
    error: any;
    loading: boolean = true;
    nextToken?: string;
    redirect?: string;
    title?: string;
    windowHeight: number = 0;
    windowWidth: number = 0;
    requestId?: string;
}


class EventSignalsForm extends EeviForm<any, EventSignalsState> implements EeviEventAccess  {


    private readonly api: EeviApi<EventSignalsState>;
    private polling = true;
    private requestId: string = "";
    private refreshing = true;


    constructor(props: any) {
        super(props, "Event Details", false, true);
        this.api = EeviApi.fromComponent<EventSignalsState>(this);
        this.state = new EventSignalsState();
    }

    /**
     * Top level navigation
     */
    protected menuLinks(): MenuLink[] {
        return [
            {title: 'Residents', link: '/residents'},
            {title: 'Carers', link: '/carers'},
            {title: 'Events', link: '/events'},
            {title: 'Event Status', link: '/event_status'},
            {title: 'Dashboard', link: '/dashboard'}
        ];
    }

    /**
     * Initial call to the back end to load the first page of signals.
     */
    protected onFormDidMount() {
        this.polling = true;
        this.api.get(
            `${apiV1}/event_signals/${this.props.match.params.eventId}`,
            undefined,
            (data) => {
                if (this.polling) {
                    this.setState({...data, loading:false});
                    // refresh it quickly, 3 seconds, after the first load to decide if the page can be updated onScroll
                    setTimeout(()=> this.refreshData(), 3000);
                }
            }
        );
    }

    /**
     *  Refresh the page is the event is not completed or just completed within 10 minutes
     */
    private refreshData() {
        if (!this.polling) {
            return;
        }
        if (this.state.event && this.state.event.eventState == "COMPLETED" && this.eventCompletedBefore(10)) {
            // For completed event, only load next block when scroll down to the bottom
            this.refreshing = false;
            return;
        }
        const nextToken = this.getNextToken();
        this.requestId = eeviId();
        let url = `${apiV1}/event_signals/${this.props.match.params.eventId}?requestId=${this.requestId}`;
        if (nextToken) {
            // this would rarely happen because each time the server returns 5000 signals by default
            url = url + `&nextToken=${encodeURIComponent(nextToken)}`;
        }
        this.api.get(
            url,
            undefined,
            (data: Partial<EventSignalsState>) => {
                if (this.polling && data.requestId === this.requestId) {
                    this.api.setState(this.getRecentSignals(data));
                    setTimeout(()=> this.refreshData(), 10000);
                }
            }
        );
    }

    private eventCompletedBefore(minutes: number): boolean {
        if (!this.state.event || !this.state.event.completedTime) {
            return false;
        }

        let now = new Date();
        let before = new Date(now.getTime() - minutes*60000);
        let completedTime = new Date(this.state.event.completedTime);
        return completedTime < before;
    }

    private getNextToken() {
        let nextToken = undefined;
        if (this.state.signals.length > 0) {
             nextToken = this.state.nextToken || this.state.signals[this.state.signals.length - 1].signalId;
        }
        return nextToken;
    }


    /**
     * Append new signals at the bottom of the list
     */
    private getRecentSignals(recentPage: Partial<EventSignalsState>): Partial<EventSignalsState> {
        if (this.state.event === undefined || this.state.signals.length == 0){
            return recentPage;
        }

        // append new events after old
        recentPage.signals = [...this.state.signals, ...recentPage.signals || []];
        return recentPage;
    }


    /**
     * If the page refreshes itself, it won't load more when you scroll down to the bottom
     */
    protected onScroll() {
        if (!this.refreshing && this.state.nextToken && this.state.event && this.state.event.eventState == "COMPLETED"
            && EeviForm.getScrollPercent() > 95) {
            const nextToken = this.state.nextToken!;
            this.setState({nextToken: undefined});
            this.onNextPage(nextToken);
        }
    }

    /**
     * Called when the user scrolls past a certain point if this.state.nextToken exists.
     * Note: Back end code that makes use of the paging capability of the Eevi Rest classes in common
     * returns nextToken with each block of data. We make another api call but rather than directly
     * updating the form state we call this.updateNextPage() to append (rather than replace) the data.
     */
    protected onNextPage(nextToken: string) {
        const url =
            `${apiV1}/event_signals/${this.props.match.params.eventId}?nextToken=${encodeURIComponent(nextToken)}`;
        this.api.get(
            url,
            undefined,
            undefined,
            (nextPage) => this.updateNextPage(nextPage)
        );
    }

    /**
     * Callback function from the api object because we want to append the set of signals to the existing.
     */
    private updateNextPage(nextPage: Partial<EventSignalsState>): Partial<EventSignalsState> {
        // append new signals to old
        nextPage.signals = [...this.state.signals, ...nextPage.signals || []];
        return nextPage;
    }

    protected onFormWillUnmount() {
        this.polling = false;
    }

    renderForm(): React.ReactNode {
        return <EventAndSignalsTable event={this.state.event!} signals={this.state.signals} eventAccess={this}/>;
    }

    // EeviEventAccess
    completeEvent(eventId: string, buttonId: string): void {
        let button =  document.getElementById(buttonId) as HTMLButtonElement;
        button.disabled = true
        button.innerText = "Completing"
        this.api.put<EventDetailsData>(
            escape(`${apiV1}/village_admin/complete/${eventId}`),
            undefined,
            undefined,
            (event) => {
                if (event.eventId === eventId && this.state.event) {
                    this.state.event.eventState = event.eventState!;
                }
                if (this.state.event!.eventState === "COMPLETED") {
                    button.innerText = "Completed";
                    button.className = "invisible"
                } else {
                    button.innerText = "Complete event";
                }
            },
            undefined,
            (error) => {console.log(`API Error: ${error.message}`); button.innerText = "Complete event"; button.disabled = false; alert(error.message)}
        )
    }
}

export default withRouter(EventSignalsForm);