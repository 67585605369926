import {EeviDeviceEditorPluginProps} from "../components/eevi_device_editor";

declare global {
    interface Window {
        stageType?: string
    }
}

function getPluginUrls(env?: string): Array<any> {
    let prefix: string | undefined;
    const host = window.location.host;
    if (host && host.includes("test.village")) {
        prefix = host.split('.', 1)[0] + ".";  // test VC3 in prod
    }
    else if (env !== undefined) {
        prefix = env === 'prod' ? "" : env + '.';
    } else {
        prefix = 'dev.'
    }

    return [
        ["st", {url: `https://${prefix}smartthings.eevi.life/components/smartthings.js`, name: "SmartThings"}],
        ["em", {url: `https://${prefix}emfit.eevi.life/components/emfit.js`, name: "Emfit"}],
        ["fluid", {url: `https://${prefix}fluid.eevi.life/components/fluid.js`, name: "Fluid"}],
        ["eview", {url: `https://${prefix}eview.eevi.life/components/eview.js`, name: "SOS Pendant"}]
    ]
}

export function generateExternalDeviceEditorProps(): EeviDeviceEditorPluginProps {
    return new EeviDeviceEditorPluginProps(getPluginUrls(window.stageType));
}