import {CarerAvailabilityData, RealtimeEvent} from "../alarms_components";

export function getDemoEvents(mode: number) {
    // @ts-ignore
    const sampleEvent: RealtimeEvent = {
        'eventId': '1632795018.772377052904.GATEWAY_BUTTON_PUSH.service_location#eevi.evhq.labs.apps#EVLA-B102',
        'accessLevel': 'eevi.evhq.labs.apps',
        'eventType': 'GATEWAY_BUTTON_PUSH',
        'eventState': 'COMPLETED',
        'eventTitle': 'Help Button Push',
        'location': 'EVLA-B102',
        'phone': '+642710008415',
        'residentFullName': 'Test for Minh',
        'residentProfilePhotoURI': undefined,
        'residentDetails': undefined,
        'hobbies': undefined,
        'associatedEventType': undefined,
        'associatedEventId': undefined,
        'associatedEventTitle': undefined,
        'assistantFullName': undefined,
        'assistantUserId': undefined,
        'assistanceRequestType': undefined,
        'assistanceRequestTime': undefined,
        'assistanceRequestLastUpdate': undefined,
        'assistanceMessage': undefined,
        'carerFullName': undefined,
        'carerUserId': undefined,
        'requestedCarerPhoneNumber': undefined,
        'requestedCarerName': undefined,
        'emergencyFullName': undefined,
        'emergencyUserId': undefined,
        'emergencyRequestType': undefined,
        'emergencyRequestTime': undefined,
        'emergencyRequestLastUpdate': undefined,
        'emergencyMessage': undefined,
        'dismissedBy': undefined,
        'notifiedUsers': [],
        'arriveTime': undefined,
        'leaveTime': undefined,
        'arrivedCarer': undefined
    }

    const now = new Date()
    const last_5_mins = new Date(now.getTime() - (5 * 60 * 1000))
    const last_10_mins = new Date(now.getTime() - (10 * 60 * 1000))
    const last_30_mins = new Date(now.getTime() - (30 * 60 * 1000))
    const last_40_mins = new Date(now.getTime() - (30 * 60 * 1000))
    const last_hour = new Date(now.getTime() - (60 * 60 * 1000))

    const openEvent = {
        ...sampleEvent,
        eventId: '1632795018.772377052904.GATEWAY_BUTTON_PUSH.service_location#eevi.evhq.labs.apps#EVLA-B102',
        accessLevel: 'eevi.evhq.labs.apps',
        eventType: 'GATEWAY_BUTTON_PUSH',
        eventState: 'OPEN',
        eventTitle: 'Help Button Push',
        triggeredTime: now.toISOString(),
        lastUpdatedTime: now.toISOString(),
        location: 'EVLA-B102 - Recent Open',
        residentFullName: 'Homer Simpson',
        carerFullName: ''
    }

    const openEvent1 = {
        ...sampleEvent,
        eventId: '1632795018.772377052904.GATEWAY_BUTTON_PUSH.service_location#eevi.evhq.labs.apps#EVLA-B102_1',
        accessLevel: 'eevi.evhq.labs.apps',
        eventType: 'GATEWAY_BUTTON_PUSH',
        eventState: 'OPEN',
        eventTitle: 'Help Button Push',
        triggeredTime: now.toISOString(),
        lastUpdatedTime: now.toISOString(),
        location: 'EVLA-B102 - Recent Open',
        residentFullName: 'Homer Simpson',
        carerFullName: ''
    }

    const openEvent2 = {
        ...sampleEvent,
        eventId: '1632795018.772377052904.GATEWAY_BUTTON_PUSH.service_location#eevi.evhq.labs.apps#EVLA-B102_2',
        accessLevel: 'eevi.evhq.labs.apps',
        eventType: 'GATEWAY_BUTTON_PUSH',
        eventState: 'OPEN',
        eventTitle: 'Help Button Push',
        triggeredTime: now.toISOString(),
        lastUpdatedTime: now.toISOString(),
        location: 'EVLA-B102 - Recent Open',
        residentFullName: 'Homer Simpson',
        carerFullName: ''
    }

    const openEvent3 = {
        ...sampleEvent,
        eventId: '1632795018.772377052904.GATEWAY_BUTTON_PUSH.service_location#eevi.evhq.labs.apps#EVLA-B102_3',
        accessLevel: 'eevi.evhq.labs.apps',
        eventType: 'GATEWAY_BUTTON_PUSH',
        eventState: 'OPEN',
        eventTitle: 'Help Button Push',
        triggeredTime: now.toISOString(),
        lastUpdatedTime: now.toISOString(),
        location: 'EVLA-B102 - Recent Open',
        residentFullName: 'Homer Simpson',
        carerFullName: ''
    }

    const takenEvent = {
        ...sampleEvent,
        eventId: '1632795018.772377052904.BED_EXIT.service_location#eevi.evhq.labs.apps#EVLA-B101',
        accessLevel: 'eevi.evhq.labs.apps',
        eventType: 'BED_EXIT',
        eventState: 'TAKEN',
        eventTitle: 'Bed Exit',
        triggeredTime: last_10_mins.toISOString(),
        lastUpdatedTime: last_5_mins.toISOString(),
        location: 'EVLA-B101 - Taken without presence',
        residentFullName: 'Marge Simpson',
        carerFullName: 'Lisa Simpson',
    }

    const completedEvent = {
        ...sampleEvent,
        eventId: '1632795018.772377052904.EMERGENCY.service_location#eevi.evhq.labs.apps#EVLA-B103',
        accessLevel: 'eevi.evhq.labs.apps',
        eventType: 'EMERGENCY',
        eventState: 'COMPLETED',
        eventTitle: 'Emergency',
        triggeredTime: last_hour.toISOString(),
        lastUpdatedTime: last_40_mins.toISOString(),
        location: 'EVLA-B103 - Completed',
        residentFullName: 'Grampa Simpson',
        carerFullName: 'Bart Simpson',
        completedTime: '2021-09-28T01:20:20.429081+00:00'
    }

    const openEvent_5mins = {
        ...sampleEvent,
        eventId: '1632795018.772377052904.PENDANT_PUSH.service_location#eevi.evhq.labs.apps#EVLA-B104',
        accessLevel: 'eevi.evhq.labs.apps',
        eventType: 'PENDANT_PUSH',
        eventState: 'OPEN',
        eventTitle: 'Help Button Push',
        triggeredTime: last_5_mins.toISOString(),
        lastUpdatedTime: last_5_mins.toISOString(),
        location: 'EVLA-B104 - Open beyond threshold',
        residentFullName: 'Mr. Burns',
        carerFullName: ''
    }

    const takenEvent_20mins = {
        ...sampleEvent,
        eventId: '1632795018.772377052904.PENDANT_PUSH.service_location#eevi.evhq.labs.apps#EVLA-B105',
        accessLevel: 'eevi.evhq.labs.apps',
        eventType: 'PENDANT_PUSH',
        eventState: 'TAKEN',
        eventTitle: 'Help Button Push',
        triggeredTime: last_30_mins.toISOString(),
        lastUpdatedTime: last_10_mins.toISOString(),
        location: 'EVLA-B105 - Taken without presence beyond threshold',
        residentFullName: 'Ned Flanders',
        carerFullName: 'Lisa Simpson'
    }

    const takenEvent_30mins = {
        ...sampleEvent,
        eventId: '1632795018.772377052904.BED_EXIT.service_location#eevi.evhq.labs.apps#EVLA-B106',
        accessLevel: 'eevi.evhq.labs.apps',
        eventType: 'BED_EXIT',
        eventState: 'TAKEN',
        eventTitle: 'Bed Exit',
        triggeredTime: last_hour.toISOString(),
        lastUpdatedTime: last_30_mins.toISOString(),
        location: 'EVLA-B106 - Taken with presence',
        residentFullName: 'Patty Bouvier',
        carerFullName: 'Maggie Simpson',
        arriveTime: last_10_mins.toISOString(),
        arrivedCarer: "Maggie Simpson"
    }

    const currentEvents = [
        completedEvent, takenEvent_30mins, takenEvent_20mins, takenEvent, openEvent_5mins, openEvent, openEvent1, openEvent2, openEvent3
    ]

    let returnedEvents: RealtimeEvent[] = []
    switch (mode) {
        case 6:
            // @ts-ignore
            returnedEvents = currentEvents.slice(0, 3)
            break
        case 7:
        case 8:
            // @ts-ignore
            returnedEvents = currentEvents
            break
        default:
            returnedEvents = []
    }

    return {
        events: returnedEvents,
        requestId: ""
    }
}

export function getDemoCarerAvailable(mode: number): CarerAvailabilityData[] {
    const enabledVillage = {
        config: {
            carer_availability_message: true,
            carer_availability_alarm: true,
        }
    }

    const disabledVillage = {
        config: {
            carer_availability_message: false,
            carer_availability_alarm: false,
        }
    }


    const carerSample = {
        careGroupCode: "eevi.evhq.labs.dan",
        carerName: "Dan",
        email: "daniel+dev@digitalcreators.com.au",
        contactPhoneNumber: "+61431677437",
        contactSmsNumber: "+61431677437",
        carerId: "785e4b4d2dcbd44b2dcb28d4cb494c2ad64b49cc5376cc492cca55084a2d2ec8cf2b4e55360002436543332313636363730b133d634b330b237323232343533300962a121f",
        customData: {
            smpers: {
                password: null,
                oldEmail: "daniel+dev@digitalcreators.com.au",
                responseClusterSize: 5,
                nativeIds: []
            }
        },
        wsConnectionId: null
    }

    // For privacy, there are no real customer or resident names here.
    const carer1 = {...carerSample, carerName: "Maggie Simpson"}
    const carer2 = {...carerSample, carerName: "Bart Simpson"}
    const carer3 = {...carerSample, carerName: "Lisa Simpson"}
    const carer4 = {...carerSample, carerName: "Ned Flanders"}
    const carer5 = {...carerSample, carerName: "Krusty the Clown"}
    const carer6 = {...carerSample, carerName: "Homer Simpson"}
    const carer7 = {...carerSample, carerName: "Apu Nahasapeemapetilon"}
    const carer8 = {...carerSample, carerName: "Edna Krabappel"}
    const carer9 = {...carerSample, carerName: "Janey Powell"}
    const carer10 = {...carerSample, carerName: "Kent Brockman"}

    let carers = [carer1, carer2, carer3, carer4, carer5, carer6, carer7, carer8, carer9, carer10]

    const village1 = {
        ...enabledVillage,
        careGroupName: "Endor Retirement Village",
        careGroupCode: "eevi.demo.1",
        availableCarers: [],
        totalAvailableCarers: 0
    }
    const village2 = {
        ...disabledVillage,
        careGroupName: "Pandora Retirement Village",
        careGroupCode: "eevi.demo.2",
        availableCarers: [],
        totalAvailableCarers: 0
    }
    const village3 = {
        ...enabledVillage,
        careGroupName: "Neverland Retirement Village",
        careGroupCode: "eevi.demo.3",
        availableCarers: [carers[0]],
        totalAvailableCarers: 1
    }
    const village4 = {
        ...enabledVillage,
        careGroupName: "Rivendell Retirement Village",
        careGroupCode: "eevi.demo.4",
        availableCarers: carers.concat(carers).concat(carers),
        totalAvailableCarers: 20
    }
    const village5 = {
        ...enabledVillage,
        careGroupName: "Naboo Retirement Village",
        careGroupCode: "eevi.demo.5",
        availableCarers: carers.slice(0, 3),
        totalAvailableCarers: 3
    }
    const village6 = {
        ...enabledVillage,
        careGroupName: "Hogwarts Retirement Village",
        careGroupCode: "eevi.demo.6",
        availableCarers: carers.slice(0, 5),
        totalAvailableCarers: 5
    }
    switch (mode) {
        case 1:
            return []
        case 2:
            return [village1]
        case 3:
            return [village3]
        case 4:
            return [village4]
        case 5:
            return [village1, village2, village3, village4, village5]
        case 6:
            return [village4]
        case 7:
            return [village6, village5]
        case 8:
            return [village2, village3, village4, village5, village6]
        default:
            return []
    }
}