import {AssistanceRequestType, Carer, EventState, EventType} from "../../../../common/web_common/core/eevi_core_data";
import {EeviDateString} from "../../../../common/web_common/components/eevi_time";
import {
    dynamicSubclass,
    localDateTimeString,
    use
} from "../../../../common/web_common/components/eevi_transform";
import {EeviColumnDefinition, EeviTable} from "../../../../common/web_common/components/eevi_table";
import {EeviEventAccess} from "../../../../common/web_common/core/eevi_core_data";
import {Col, React, Row, Button} from "../../../../common/web_common/components/eevi_react_exports";
import {withLineBreaks, withSpaces} from "../../../../common/web_common/components/eevi_util";
import {EeviEventHandler} from "../../../../common/web_common/components/eevi_event_handler";
import {bold, italic} from "../../../../common/web_common/components/eevi_style";
import {eeviGlobal} from "../../../../common/web_common/components/eevi_context";

export interface ResponseGroupData {
    groupName: string;
    responders: Carer[];
    responseClusterSize: number;
}

export interface EventDetailsData {
    eventId: string;
    accessLevel: string;

    eventType: EventType;

    eventState: EventState;
    eventTitle: string;
    triggeredTime: EeviDateString;
    lastUpdatedTime: EeviDateString;
    completedTime: EeviDateString;
    location: string;

    phone: string;
    residentFullName: string;
    residentProfilePhotoURI?: string;
    residentDetails?: string;
    hobbies?: string;

    associatedEventType?: string;
    associatedEventId?: string;
    associatedEventTitle?: string;

    assistantFullName?: string;
    assistantUserId?: string;
    assistanceRequestType?: AssistanceRequestType;
    assistanceRequestTime?: EeviDateString;
    assistanceRequestLastUpdate?: EeviDateString;
    assistanceMessage?: string;

    carerFullName?: string;
    carerUserId?: string;
    requestedCarerPhoneNumber?: string;
    requestedCarerName?: string;

    emergencyFullName?: string;
    emergencyUserId?: string;
    emergencyRequestType?: AssistanceRequestType;
    emergencyRequestTime?: EeviDateString;
    emergencyRequestLastUpdate?: EeviDateString;
    emergencyMessage?: string;

    dismissedBy?: string[]
    notifiedUsers?: string[]

    arriveTime?: EeviDateString;
    leaveTime?: EeviDateString;
    arrivedCarer?: string;
}

export interface EventResponseData extends EventDetailsData {
    responseGroups?: ResponseGroupData[]
}

/**
 * Instances of this class will be dynamically subclassed from EventDetailsData to become EventDetails
 */
class EventDetailsCompute {
    protected get base(): EventDetailsData {
        return this as any;
    }

    get responderFullName(): string | undefined {
        if (this.base.eventType === "ASK_ASSISTANCE") {
            return this.base.assistantFullName;
        } else if (this.base.eventType === "RAISE_EMERGENCY") {
            return this.base.emergencyFullName;
        } else {
            return this.base.carerFullName;
        }
    }

    get allDetails(): string | undefined {
        return withSpaces(
            this.base.requestedCarerName,
            this.base.requestedCarerPhoneNumber,
            `${this.base.eventType}:`,
            this.base.eventState,
            this.base.location,
            this.base.residentFullName);
    }

    get responseGroupsFormatted(): string | undefined {
        const data = this as any as EventResponseData;
        if (!data.responseGroups || data.responseGroups.length == 0) {
            return undefined;
        }
        let counter = 0;
        let results = [];
        for (const responseGroup of data.responseGroups) {
            counter += 1;
            const emails = responseGroup.responders.map(rg => rg.email);
            results.push(`Group ${counter}: ` + emails.join(", "));
        }
        return results.join('\n');
    }
}


export type EventDetails = EventDetailsCompute & EventDetailsData;


const eventColumnDefinitions: EeviColumnDefinition<EventDetails>[] = [
    {
        columnHeaderTitle: "Date",
        dataKey: "triggeredTime",
        formatter: localDateTimeString
    },
    {
        columnHeaderTitle: "Event",
        dataKey: "allDetails",
        styles: [use('keep-lines'), use('text-justify')]
    },
    {
        columnHeaderTitle: "Responder",
        dataKey: "responderFullName",
    },
];

export function EventsTable(props: { events: EventDetailsData[], onSelect: EeviEventHandler<EventDetails> }) {
    const items: EventDetails[] = props.events.map(e => dynamicSubclass(e, EventDetailsCompute));
    if (items.length == 0) {
        return <h5 className="mx-auto p-5">No events</h5>;
    }
    return <EeviTable
        columnDefinitions={eventColumnDefinitions}
        primaryKey="eventId"
        data={items}
        onSelect={props.onSelect}/>;
}

export interface SignalDetailsData {
    signalId: string;
    signalType: string;
    signalTime: string;
    description: string;
    contextInfo: string;
}

/**
 * Signals
 * Instances of this class will be dynamically subclassed from SignalDetailsData to become SignalDetails
 */
class SignalDetailsCompute {
    private get base(): SignalDetailsData {
        return this as any;
    }

    get dateAndSignalType(): string {
        return withLineBreaks(
            localDateTimeString(this.base.signalTime),
            this.base.signalType,
            this.base.contextInfo
        );
    }

}

type SignalDetails = SignalDetailsCompute & SignalDetailsData;


const signalColumnDefinitions: EeviColumnDefinition<SignalDetails>[] = [
    {
        columnHeaderTitle: "Signal",
        dataKey: "dateAndSignalType",
        styles: [use('keep-lines')],
        width: "10%",
    },
    {
        columnHeaderTitle: "Details",
        dataKey: "description",
        styles: [use('keep-lines')]
    },
];

function Prop(props: { name: string, value?: string }) {
    function getSize(): number {
        if (props.value!.length > 25) {
            return 6;
        } else {
            return 3;
        }
    }

    return props.value ?
        <Col xs={12} sm={getSize()}>
            <div className="mr-2 mt-3" style={bold}>{props.name}</div>
            <div className="keep-lines">{props.value}</div>
        </Col>
        :
        <></>;

}


function renderCompleteButton(eventId: string, eventAccess: EeviEventAccess) {
    const buttonId = `complete_event${eventId}`
    return  <Button
        id={buttonId}
        disabled={false}
        onClick={() => eventAccess.completeEvent(eventId, buttonId)}
        size="sm"
        color="outline-secondary"
        style={{width: "130px"}}
        className={"eevi_compelete_button mr-2"}>
        <div style={bold}>Complete event</div>
    </Button>
}

export function EventAndSignalsTable(props: { event?: EventResponseData, signals: SignalDetailsData[], eventAccess: EeviEventAccess }) {
    const items: SignalDetails[] = props.signals.map(e => dynamicSubclass(e, SignalDetailsCompute));
    let eventHeader = <></>;
    if (props.event) {
        const e = dynamicSubclass(props.event, EventDetailsCompute);
        eventHeader = <Row className="border pb-4 pt-4 mt-sm-4 ml-sm-1 mr-sm-1">
            <Col className="text-left">
                    {eeviGlobal.isVillageAdmin() && (e.eventState === "OPEN" || e.eventState.includes("TAKEN")) && renderCompleteButton(e.eventId, props.eventAccess)}
            </Col>
            <Col xs={12}>
                <div style={bold}>{e.eventType}: {e.eventState}</div>
            </Col>
            <Col xs={12}>
                <div style={italic}>{e.location} {e.residentFullName}</div>
            </Col>
            <Prop name="Triggered" value={localDateTimeString(e.triggeredTime)}/>
            <Prop name="Updated" value={localDateTimeString(e.lastUpdatedTime)}/>
            <Prop name="Carer" value={e.responderFullName}/>
            <Prop name="Assistant" value={e.assistantFullName}/>
            <Prop name="Emergency Assist" value={e.emergencyFullName}/>
            <Prop name="People Notified" value={(e.notifiedUsers || []).join(", ")}/>
            <Prop name="Dismissed By" value={(e.dismissedBy || []).join(", ")}/>
            <Prop name="Response Groups" value={e.responseGroupsFormatted}/>
        </Row>;

    }
    return <>
        {eventHeader}
        {
            items.length > 0 && <div className="mt-4">
                <EeviTable
                    columnDefinitions={signalColumnDefinitions}
                    primaryKey="signalId"
                    data={items}
                    onSelect={undefined}
                    fixedWidth={true}
                />
            </div>
        }
    </>;
}