import {React, Table,withRouter} from "../../../../common/web_common/components/eevi_react_exports";
import {EeviForm, EeviFormState} from "../../../../common/web_common/forms/eevi_form";
import {EeviApi} from "../../../../common/web_common/components/eevi_api";
import {apiV1, MenuLink} from "../../../../common/web_common/containers/eevi_std_container";
import {eeviId} from "../../../../common/web_common/components/eevi_util";

type rawHistoricalData = {[date: string]: {[time: string]: string}}
type HistoricalData = [Date, string]

interface DeviceBasicInfo {
    serviceAddressCode: string
    deviceType: string
    deviceName: string
    deviceModel: string
}

class DeviceConnectivityState implements EeviFormState {
    error: any
    loading: boolean = true
    nextToken?: string
    redirect?: string
    title?: string
    windowHeight: number = 0
    windowWidth: number = 0

    requestId?: string
    deviceId: string = ""
    deviceInfo?: DeviceBasicInfo
    payload?: rawHistoricalData
    historicalData = this.parseRawData

    protected *parseRawData(): IterableIterator<HistoricalData> {
        if (!this.payload){
            return []
        }

        // We are going to show the latest data entry first
        for (const [date, time_entry] of Object.entries(this.payload).reverse()) {
            for (const [time, online_status] of Object.entries(time_entry).reverse()) {
                yield [new Date(`${date} ${time}`), online_status]
            }
        }
    }
}

class DeviceConnectivityForm extends EeviForm<any, DeviceConnectivityState>{
    private readonly api: EeviApi<DeviceConnectivityState>
    private requestId = ""
    private startTime: null | Date = null
    private endTime: null | Date = null
    private deviceId: string = ""

    constructor(props: any) {
        super(props, "Device Connectivity", false, true)
        this.api = EeviApi.fromComponent<DeviceConnectivityState>(this)
        this.state = new DeviceConnectivityState()
    }

    protected menuLinks(): MenuLink[] {
        return [
            {title: 'Residents', link: '/residents'},
            {title: 'Carers', link: '/carers'},
            {title: 'Events', link: '/events'},
            {title: 'Event Status', link: '/event_status'},
            {title: 'Dashboard', link: '/dashboard'}
        ]
    }

    protected onFormDidMount() {
        this.requestId = eeviId()

        let parameters = new URLSearchParams(window.location.search)

        // By default, start time is 3 hours before end time (now)
        // Note the start and end times are in UTC
        let now = new Date();
        let utcNow = Date.UTC(
            now.getUTCFullYear(),now.getUTCMonth(), now.getUTCDate() ,
            now.getUTCHours(), now.getUTCMinutes(), 0, 0
        );
        const offsetHours:number = 3

        let startTimeString=decodeURIComponent(parameters.get("startTime") || "")
        if (startTimeString) {
            this.startTime = new Date(startTimeString)
        }
        else
        {
            this.startTime = new Date(utcNow)
            this.startTime.setHours(this.startTime.getHours() - offsetHours);
        }

        let endTimeString = decodeURIComponent(parameters.get("endTime") || "")
        if (endTimeString) {
            this.endTime = new Date(endTimeString)
        } else
        {
            let now = new Date();
            let utc = Date.UTC(
                now.getUTCFullYear(),now.getUTCMonth(), now.getUTCDate() ,
                now.getUTCHours(), now.getUTCMinutes(), 0, 0
            );
            this.endTime = new Date(utc)
        }

        this.deviceId = parameters.get("deviceId") || ""

        if (this.deviceId) {
            this.api.get(
                `${apiV1}/device/connectivity?` +
                `deviceId=${this.deviceId}` +
                (this.startTime.getTime() ? `&startTime=${encodeURIComponent(this.startTime.toISOString())}` : "") +
                (this.endTime.getTime() ? `&endTime=${encodeURIComponent(this.endTime.toISOString())}` : "") +
                `&requestId=${this.requestId}`,
                undefined,
                (data: Partial<DeviceConnectivityState>) => {
                    if (this.requestId === data.requestId) {
                        this.setState({...this.state, ...data, loading: false})
                    }
                },
                undefined,
                true,
            )
        }
        else {
            this.setState({loading: false, error: 'Specify deviceId!'})
        }
    }

    protected errorHandler(error: Partial<any>){
        this.setState({...this.state, loading: false, error: error});
    }

    protected renderForm(): any {
        const datetimeStringFormat = {day: 'numeric', month: 'numeric', year: 'numeric', hour: 'numeric', minute: 'numeric'}
        const content: JSX.Element[] = []

        for (const e of this.state.historicalData()){
            let online = Boolean(parseInt(e[1]))
            content.push(
                <tr className={online ? "device-online-background" : "device-offline-background"} key={e[0].toISOString()}>
                    <td>{e[0].toLocaleString('en-GB',datetimeStringFormat)}</td>
                    <td>{online ? "ONLINE" : "OFFLINE"}</td>
                </tr>
            )
        }
        return this.state.error ? <div/> :
            <div>
            <p style={{fontSize: "1.7em", paddingTop: "3vh"}}>
                Connectivity status for {this.state.deviceInfo?.deviceModel}: {this.state.deviceInfo?.deviceName} at {this.state.deviceInfo?.serviceAddressCode}
            </p>
            <p style={{fontSize: "1.33em", paddingBottom: "1vh"}}>
                between {this.startTime?.toLocaleString('en-GB',datetimeStringFormat)} and {this.endTime?.toLocaleString('en-GB',datetimeStringFormat)}
            </p>
            {
                content.length ?
                <Table id={`device-connectivity-${this.state.deviceId}`} style={{maxWidth:'40vw'}}>
                    <thead>
                        <tr>
                            <th>Time</th>
                            <th>Status</th>
                        </tr>
                    </thead>
                    <tbody>
                        {content}
                    </tbody>
                </Table>
                : <p>No record found for this period</p>
            }
        </div>
    }
}

export default withRouter(DeviceConnectivityForm);