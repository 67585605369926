import {
    React,
    Button,
    Container,
    Row,
    Col,
    UncontrolledTooltip, Input, Label, Nav, NavItem, NavLinkStrap, TabContent, TabPane
} from "../../../../common/web_common/components/eevi_react_exports";
import {EeviCoreContext} from "../../../../common/web_common/core/components/eevi_core_context";
import {CareGroup, Carer, ResponseGroupType} from "../../../../common/web_common/core/eevi_core_data";
import {Results} from "./results";
import {EeviCollapseHeading} from "../../../../common/web_common/components/eevi_collapse_heading";
import {boldItalic, italic} from "../../../../common/web_common/components/eevi_style";
import {moveDown, moveUp, setEeviUid, setInputFocus} from "../../../../common/web_common/components/eevi_util";
import {EeviFormFeedback} from "../../../../common/web_common/components/eevi_form_feedback";
import {EeviOptionalInput} from "../../../../common/web_common/components/eevi_optional_input";
import {EeviTooltip} from "../../../../common/web_common/components/eevi_tooltip";
import {
    dynamicSubclass,
    localDateTimeString
} from "../../../../common/web_common/components/eevi_transform";
import {VillageCarer} from "./default_device_editor";
import {eeviGlobal} from "../../../../common/web_common/components/eevi_context";


function ResponseGroupsTabs(props: { careGroup: CareGroup, hidden: boolean }) {
    if (props.hidden) {
        return <></>;
    }
    const {careGroup} = props;
    const careGroupCode = careGroup.careGroupCode;
    const {access} = React.useContext(EeviCoreContext);
    const selectedEventType = access!.getSelectedResponseGroupType() ?? ResponseGroupType["Alarm Response"];
    return <>
        <Nav tabs>
            {
                [...ResponseGroupType].map((eventType) =>
                    <NavItem key={`nav.${careGroupCode}.${eventType}`}>
                        <NavLinkStrap
                            className={eventType === selectedEventType ? "active font-weight-bold" : ''}
                            onClick={() => access!.selectResponseGroupType(eventType)}
                        >
                            {eventType}
                        </NavLinkStrap>
                    </NavItem>
                )
            }
        </Nav>
        <TabContent activeTab={selectedEventType as string}>
            {
                [...ResponseGroupType].map((eventType) =>
                    <TabPane
                        key={`tab.${careGroupCode}.${eventType}`}
                        tabId={eventType}
                        className="border-left border-right border-bottom mb-2"
                    >
                        <CarerGroup
                            hidden={eventType !== selectedEventType}
                            careGroup={careGroup}
                            key={setEeviUid(careGroup, "CarerGroup:")}/>
                    </TabPane>
                )
            }
        </TabContent>
    </>;
}

export function CarersGroupList() {
    const {access} = React.useContext(EeviCoreContext);
    const careGroups = access!.getCareGroups();
    if (careGroups !== "loading" && careGroups.length === 1) {
        access!.selectCareGroup(careGroups[0].careGroupCode);
    }
    const collapsed = (group: CareGroup) => group.careGroupCode !== access!.getSelectedCareGroup();
    return <Results items={careGroups} none="No carers found..." render={
        group =>
            <EeviCollapseHeading
                defaultToCollapsed={collapsed(group)}
                key={setEeviUid(group, "CGL:")}
                heading={group.careGroupName}
                ikey={setEeviUid(group, "ICGL:")}
                onChange={
                    (collapse) => access!.selectCareGroup(collapse ? undefined : group.careGroupCode)
                }
            >
                <ResponseGroupsTabs hidden={collapsed(group)} careGroup={group} key={setEeviUid(group, "RGT:")}/>
            </EeviCollapseHeading>
    }/>;
}

function CarerGroup(props: { careGroup: CareGroup, hidden: boolean }) {
    if (props.hidden) {
        // stop retrieving all care groups upfront
        return <></>;
    }
    const group = props.careGroup;
    const {access} = React.useContext(EeviCoreContext);
    const carers = access!.getCarers(group.careGroupCode);
    let loading = access!.carerBeingEdited && access!.carerBeingEdited.loading;
    let concurrentAlerts = 0;
    if (carers !== "loading" && carers.length > 0) {
        const customData: any = carers[carers.length - 1].customData ?? {smpers: {responseClusterSize: 1}};
        concurrentAlerts = customData['smpers']['responseClusterSize'] || 1;
        for (let nextCarer of carers) {
            loading = loading || nextCarer.loading;
        }
    }
    let disableEdit: boolean = access!.carerBeingEdited !== undefined;
    const groupNumber = 1;
    const groupType = access!.getSelectedResponseGroupType();

    if (groupType === "Alarm Response" && group.devicePlatforms.values.includes("eview")){
        disableEdit = true;
    }

    function insertCarer() {
        access!.insertCarer(group.careGroupCode);
    }

    function setConcurrentCalls(value: number) {
        if (value && value !== concurrentAlerts) {
            if (value < 1) {
                value = 1;
            } else if (value > 50) {
                value = 50;
            }
            access!.getCarers(props.careGroup.careGroupCode, true, value);
        }
    }

    return <div className="p-2 mb-3 ml-2" key={setEeviUid(group, 'c1:')}>
        <Row>
            <Col xs="auto" className="mr-auto mt-1 pr-0">
                <span className="d-block d-sm-none" style={boldItalic}>{`# ${groupNumber}`}</span>
                <span className="d-none d-sm-block" style={italic}>{`${groupType} Team ${groupNumber}`}</span>
            </Col>
            <Col xs="auto" className="text-right p-0 pr-2">
                <Row className={disableEdit ? "invisible" : ""}>
                    <Col xs="auto" className={concurrentAlerts == 0 ? "invisible" : "pl-0 pr-0 pt-1 text-right"}>
                        <span>concurrent alerts:</span>
                    </Col>
                    <Col xs="auto" className={concurrentAlerts == 0 ? "invisible" : "p-0 pl-1 pr-1"}>
                        <Input
                            id={setEeviUid(group, "concurrent")}
                            type="number"
                            min="1"
                            max="50"
                            className="eevi_edit_text w-25-m w-50-ns"
                            style={{lineHeight: "0.8rem"}}
                            value={concurrentAlerts}
                            onChange={(e) => setConcurrentCalls(parseInt(e.target.value))}
                        />
                    </Col>
                    <Col xs="auto" className="text-right pl-2">
                        <Button size="sm"
                                style={carers.length > 1 ? {width: "105px"} : {}}
                                color="outline-secondary"
                                className={disableEdit ? "invisible" : "eevi_add_button mr-2"}
                                onClick={insertCarer}>
                            add carer
                        </Button>
                    </Col>
                </Row>

            </Col>
        </Row>
        <div className="border-top mb-2 mt-2">
            <Container className="mb-0 mt-0 p-0" key={setEeviUid(group, 'c2:')}>
                <Results
                    key={setEeviUid(group, 'c3:')}
                    loading={loading}
                    items={carers}
                    none="No carers..."
                    render={
                        (carer: Carer, index) => <>
                            <CarerCard
                                key={setEeviUid(carer, 'c4')}
                                carer={carer}
                                index={index}
                                carers={carers as Carer[]}
                                noEdit={disableEdit}
                            />
                        </>
                    }/>
            </Container>
        </ div>
    </ div>;
}

function CarerPassword(props: { carer: Carer }) {
    if (!eeviGlobal.isVillageAdmin()) {
        return <></>;
    }
    const carer = dynamicSubclass(props.carer, VillageCarer);
    const {access} = React.useContext(EeviCoreContext);
    const [editMode, setEditMode] = React.useState(false);
    const [password, setPassword] = React.useState(carer.password);
    carer.password = password;

    function toggleEditMode() {
        setPassword(undefined);
        setEditMode(!editMode);
    }

    return <>
        <Label className="eevi_text ml-4 mt-0">
            <Input
                type="checkbox"
                autoComplete="off"
                className="eevi_check_box"
                checked={editMode}
                onChange={toggleEditMode}/>
            {' '}
            {"Set password for Eevi login"}
        </Label>
        {
            editMode &&
            <Input
                id={setEeviUid(carer, "carer_password")}
                data-lpignore="true"
                autoComplete="off"
                type="password"
                className="eevi_edit_text"
                hidden={!editMode}
                value={password || ''}
                onChange={(e) => setPassword(e.target.value)}
            />
        }
        {
            editMode &&
            <EeviTooltip target={setEeviUid(carer, "carer_password")}>
                {`Account password for ${carer.email || "email"}`}
            </EeviTooltip>
        }
        <EeviFormFeedback fieldName="password" state={access!}/>

    </>;
}


function CarerCard(props: { carer: Carer, index: number, carers: Carer[], noEdit: boolean }) {
    const carer = props.carer;
    const carers = props.carers;
    const index = props.index;
    const {access} = React.useContext(EeviCoreContext);
    const editMode = access!.carerBeingEdited === carer;
    const [stateCount, setStateCount] = React.useState(0);
    const disableEdit: boolean = access!.carerBeingEdited !== undefined || props.noEdit;

    function forceUpdate() {
        setStateCount(stateCount + 1);
    }

    function edit() {
        access!.editCarer(carer);
    }

    function logout() {
        access!.logoutCarer(carer, updateCarer);
    }

    function updateCarer(carerUpdated: Carer) {
        carer.isOnline = carerUpdated.isOnline;
        forceUpdate()
    }

    function moveCarerUp() {
        const newIndex = moveUp(index, carers, true);
        // access!.onModelUpdate();
        access!.saveCarer(carer, newIndex, true);
    }

    function moveCarerDown() {
        const newIndex = moveDown(index, carers, true);
        // access!.onModelUpdate();
        access!.saveCarer(carer, newIndex, true);
    }


    function upDownButtonStyle(isUp: boolean) {
        const color = {color: "#6c757d"};
        // Change the height on 1st and last buttons
        if ((isUp && index === carers.length - 1) ||
            (!isUp && index === 0)) {
            return {...color, height: "100%"};
        } else {
            // return {...color, fontSize: ".65em" };
            return {...color, height: "100%"};
        }
    }

    function formIncomplete(): boolean {
        return !(carer.carerName &&
            carer.email &&
            carer.contactSmsNumber);
    }

    function save() {
        access!.saveCarer(carer, index, true);
    }

    function cancelEdit() {
        access!.cancelEditCarer();
    }

    function remove() {
        access!.removeCarer(carer);
    }

    function renderUpDownButtons() {
        let loading: boolean | undefined = false;
        for (let nextCarer of carers) {
            loading = loading || nextCarer.loading;
        }
        return <span className="btn-group-vertical btn-group-sm p-0">
            {
                index !== 0 && !disableEdit &&
                <>
                    <Button
                        id={setEeviUid(carer, "up_carer")}
                        disabled={loading}
                        onClick={moveCarerUp}
                        className={editMode ? "invisible" : "eevi_edit_button btn-sm pb-0 pt-0"}
                        style={upDownButtonStyle(true)}>
                        <i className="fa fa-arrow-up fa-pull-left fa-xs"/>
                    </Button>
                    <EeviTooltip target={setEeviUid(carer, "up_carer")}>
                        Shift carer up emergency response queue
                    </EeviTooltip>
                </>
            }
            {
                index !== carers.length - 1 && !disableEdit &&
                <>
                    <Button
                        id={setEeviUid(carer, "down_carer")}
                        disabled={loading || index === carers.length - 1 || access!.carerBeingEdited !== undefined}
                        onClick={moveCarerDown}
                        className={editMode ? "invisible" : "eevi_edit_button btn-sm pt-0 pb-0"}
                        style={upDownButtonStyle(false)}>
                        <i className="fa fa-arrow-down fa-pull-left fa-xs"/>
                    </Button>
                    <EeviTooltip target={setEeviUid(carer, "down_carer")}>
                        Shift carer down emergency response queue
                    </EeviTooltip>
                </>
            }
        </span>;
    }

    function renderLogoutButton() {
        return  <Button
            id={setEeviUid(carer, "logout_carer")}
            onClick={logout}
            size="sm"
            color="outline-secondary"
            style={{width: "90px"}}
            className={ carer.isOnline ? "eevi_edit_button mr-2" : "invisible"}>
            logout carer
        </Button>
    }

    function renderForDisplay() {
        const onlineStatus = [
            'https://s3.ap-southeast-2.amazonaws.com/eevi.life/assets/green-dot.png',
            'https://s3.ap-southeast-2.amazonaws.com/eevi.life/assets/grey-dot.png'
        ]
        return <Row key={setEeviUid(carer, "rdp")} className="mb-3 mb-2 mt-2">
            <Col xs="0.2" sm="0.2" md="0.2">
                <img className="eevi_online_status ml-2" src={carer.isOnline ? onlineStatus[0] : onlineStatus[1]}/>
            </Col>
            <Col xs="9" sm="9" md="9">
                <Container>
                    <Row>
                        <Col className="p-0 font-weight-bold mr-2">{carer.carerName}</Col>
                        <Col className="p-0 mr-4">{carer.email}</Col>
                        <Col className="p-0 mr-2">{carer.contactPhoneNumber}</Col>
                        <Col className="p-0 mr-2">
                            {carer.lastLoginTime ? localDateTimeString(carer.lastLoginTime) : "--------------"}
                        </Col>
                        <Col className="p-0">{carer.isOnline ? "Logged in": ""}</Col>
                    </Row>
                </Container>
            </Col>
            <Col className="text-right">
                <span className="btn-group btn-group-sm p-0">
                    {eeviGlobal.isVillageAdmin() && renderLogoutButton()}
                    <Button
                        id={setEeviUid(carer, "edit_carer")}
                        onClick={edit}
                        size="sm"
                        color="outline-secondary"
                        className={editMode || disableEdit ? "invisible" : "eevi_edit_button"}>edit carer</Button>
                    {carers.length > 1 && renderUpDownButtons()}
                </span>
                <UncontrolledTooltip target={setEeviUid(carer, "edit_carer")} delay={400}>
                    change carer details
                </UncontrolledTooltip>
            </Col>
        </Row>;
    }

    function renderForEdit() {
        return <Row key={setEeviUid(carer, "rfe")} className="mb-3 mt-1">
            <Col className="card shadow p-4 border">
                <Input
                    data-lpignore="true"
                    autoComplete="off"
                    id={setEeviUid(carer, "carer_name")}
                    innerRef={carer.carerName ? undefined : setInputFocus}
                    type="text"
                    onChange={(e) => {
                        carer.carerName = e.target.value;
                        forceUpdate();
                    }}
                    className="eevi_edit_text"
                    value={carer.carerName || ''}
                    placeholder="carer's name"/>
                <EeviFormFeedback fieldName="carerName" state={access!}/>
                <UncontrolledTooltip
                    delay={400}
                    target={setEeviUid(carer, "carer_name")}>Emergency response staff</UncontrolledTooltip>
                <Input
                    id={setEeviUid(carer, "carer_email")}
                    data-lpignore="true"
                    autoComplete="off"
                    type="email"
                    onChange={(e) => {
                        carer.email = e.target.value;
                        if (carer.email) {
                            carer.email = carer.email.toLowerCase();
                        }
                        forceUpdate();
                    }}
                    className="eevi_edit_text"
                    value={carer.email || ''}
                    placeholder="email"/>
                <EeviFormFeedback fieldName="email" state={access!}/>
                <UncontrolledTooltip
                    delay={400}
                    target={setEeviUid(carer, "carer_email")}>email for notifications</UncontrolledTooltip>

                <Input
                    id={setEeviUid(carer, "carer_voice_phone_number")}
                    data-lpignore="true"
                    autoComplete="off"
                    type="tel"
                    onChange={(e) => {
                        carer.contactPhoneNumber = e.target.value;
                        forceUpdate();
                    }}
                    className="eevi_edit_text"
                    value={carer.contactPhoneNumber || ''}
                    placeholder="Number for voice calls: e.g. +61419123456"/>
                <EeviFormFeedback fieldName="contactPhoneNumber" state={access!}/>
                <UncontrolledTooltip
                    delay={400}
                    target={setEeviUid(carer, "carer_voice_phone_number")}>voice calls</UncontrolledTooltip>
                <EeviOptionalInput
                    id={setEeviUid(carer, "carer_sms_phone_number")}
                    type="tel"
                    onValueChange={(newValue) => {
                        carer.contactSmsNumber = newValue;
                        forceUpdate();
                    }}
                    value={carer.contactSmsNumber || ''}
                    sameAsValue={carer.contactPhoneNumber || ''}
                    placeholder="Number for SMS: e.g. +61419123456"
                    checkLabel="Receive voice and SMS messages on the same number"
                    className="eevi_edit_text"/>
                <EeviFormFeedback fieldName="contactSmsNumber" state={access!}/>
                <UncontrolledTooltip
                    delay={400}
                    target={setEeviUid(carer, "carer_sms_phone_number")}>
                    For SMS notifications
                </UncontrolledTooltip>
                <CarerPassword carer={props.carer} key={setEeviUid(carer, "CarerPassword")}/>
                <div className="text-right">
                    <Button
                        disabled={formIncomplete()}
                        onClick={save}
                        className="eevi_edit_button"
                        size="sm"
                        color="outline-secondary"
                        id={setEeviUid(carer, "save")}>
                        <span>save</span>
                    </Button>
                    <Button
                        onClick={cancelEdit}
                        size="sm" color="outline-secondary"
                        className="m-2 eevi_edit_button"
                        id={setEeviUid(carer, "cancel")}>cancel</Button>
                    <Button
                        disabled={!carer.carerId}  // insert
                        onClick={remove}
                        className="eevi_edit_button"
                        size="sm"
                        color="outline-secondary"
                        id={setEeviUid(carer, "remove")}>remove</Button>
                    <UncontrolledTooltip target={setEeviUid(carer, "save")} delay={400}>
                        Update database
                    </UncontrolledTooltip>
                    <UncontrolledTooltip target={setEeviUid(carer, "cancel")} delay={400}>
                        Return without updating
                    </UncontrolledTooltip>
                    <UncontrolledTooltip target={setEeviUid(carer, "remove")} delay={400}>
                        Remove carer from this care group
                    </UncontrolledTooltip>
                </div>
            </Col>
        </Row>;
    }


    return editMode ? renderForEdit() : renderForDisplay();

}


