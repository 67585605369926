export interface EeviLookupItem {
    value: string
    label: string
}

type StringableProperties = { [key: string]: { toString(): string } };

export class EeviLookup {
    /**
     * Convert an enumerated type into an Array of Lookup Items
     * @param enumType
     */
    static arrayFromEnum(enumType: any): Array<EeviLookupItem> {
        const items: Array<EeviLookupItem> = [];
        for (const value in enumType) {
            items.push({value: value, label: enumType[value]});
        }
        return items;
    }

    static forValue(value: string | undefined, items?: Array<EeviLookupItem>): EeviLookupItem | undefined {
        if (!value || !items) {
            return undefined;
        }
        const filtered_items = items.filter(i => i.value === value);
        if (filtered_items.length > 0) {
            return filtered_items[0];
        } else {
            return undefined;
        }
    }


    static labelForValue(value: string | undefined, items: Array<EeviLookupItem>): string | undefined {
        const item = EeviLookup.forValue(value, items);
        if (item) {
            return item.label;
        } else
            return undefined;
    }

    static fromObjects<T extends StringableProperties >(
        valueProp: keyof T, labelProp: keyof T, objects: Iterable<T>): EeviLookupItem[] {
        const items: EeviLookupItem[] = [];
        for (const obj of objects) {
            items.push({value: obj[valueProp].toString(), label: obj[labelProp].toString()});
        }
        return items;
    }

}